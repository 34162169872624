import { render, staticRenderFns } from "./editorial.vue?vue&type=template&id=4ec8bfec&scoped=true&"
import script from "./editorial.vue?vue&type=script&lang=js&"
export * from "./editorial.vue?vue&type=script&lang=js&"
import style0 from "./editorial.vue?vue&type=style&index=0&id=4ec8bfec&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ec8bfec",
  null
  
)

export default component.exports