<template>
  <div class="editorial_box" v-loading="loading">
    <div class="enter_item">
      <div class="part1">
        <div class="title">客户信息</div>
        <div class="input_box">
          <div class="item">
            <span class="item_name"><i>*</i>所在地区</span>
<!--            <el-cascader
                v-model="value"
                :options="options"
                disabled
                @change="handleChange"></el-cascader>-->
            <el-input
                placeholder="请输入内容"
                v-model="regionalInformation"
                :disabled="true">
            </el-input>
          </div>
          <div class="item">
            <span class="item_name"><i>*</i>所属网格</span>
            <el-select v-model="owningGrid" disabled placeholder="请选择企业名称">
              <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <span class="item_name"><i>*</i>企业名称</span>
            <el-select v-model="enterpriseName" disabled placeholder="请选择企业名称">
              <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="input_box">
          <div class="item">
            <span class="item_name"><i>*</i>联系人</span>
            <el-input v-model="allData.link_people" placeholder="请填写联系人"></el-input>
          </div>
          <div class="item">
            <span class="item_name"><i>*</i>联系电话</span>
            <el-input v-model="allData.link_phone" placeholder="请填写联系电话"></el-input>
          </div>
          <div class="item">
            <span class="item_name"><i>*</i>商企经理</span>
            <el-select v-model="businessManager" disabled placeholder="请选择商企经理">
              <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="part2">
        <div class="title">商机信息</div>
        <div class="input_box">
          <div class="item">
            <span class="item_name"><i>*</i>商机名称</span>
            <el-input v-model="businessTitle" placeholder="请输入商机名称" disabled class="lang_input"></el-input>
          </div>
          <div class="item short_distance">
            <span class="item_name"><i>*</i>商机来源</span>
            <el-select v-model="sjInfo[0].id" placeholder="请输入商机来源" disabled v-if="sjInfo[0].dict_name == '诊断报告转商机'">
              <el-option
                  v-for="item in sjInfo"
                  :key="item.id"
                  :label="item.dict_name"
                  :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="allData.source_id" placeholder="请输入商机来源" v-else>
              <el-option
                  v-for="item in businessSource"
                  :key="item.id"
                  :label="item.dict_name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="item short_distance" style="margin-right: 48px">
            <span class="item_name">预计签约时间</span>
            <el-date-picker
                v-model="allData.predict_time"
                @change="handleDateChange"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
          </div>
          <div class="item short_distance" style="margin-right: 10px">
            <span class="item_name">预计合同金额</span>
            <el-input v-model="allData.predict_contract_money" placeholder="请填写预计合同金额" style="width: 300px"></el-input>
            <span style="margin-left: 5px">万元</span>
          </div>
        </div>

        <!--      客户需求简介-->
        <div class="main_solution">
          <div class="item">
            <span class="item_name"><i>*</i>客户需求简介</span>
            <el-input
                type="textarea"
                :rows="2"
                placeholder="请填写客户需求简介"
                v-model="allData.content">
            </el-input>
          </div>
        </div>

        <!--      主要业务类型-->
        <div class="main_type tab_main" style="margin-top: 20px">
          <div class="item">
            <span class="item_name"><i>*</i>主要业务类型</span>
            <div class="tags">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane v-for="(item,index) in businessList" :key="item.id" :label="item.dict_name" :name="getActive(item)">
                  <div class="single_choice">
                    <el-radio v-for="(item,index) in item.list" :key="item.id" v-model="allData.main_business_type" :label="item.id">{{ item.dict_name }}</el-radio>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>

        <!--      次要业务类型-->
        <div class="main_type tab_main" style="margin-top: 30px">
          <div class="item">
            <span class="item_name">次要业务类型</span>
            <div class="tags">
              <el-tabs v-model="activeName1">
                <el-tab-pane label="创新业务" name="first">
                  <div class="single_choice">
                    <el-checkbox-group v-model="allData.minor_business_type.innovate">
                      <el-checkbox v-for="(item,index) in businessInnovateType" :key="item.id" :label="item.id">{{item.dict_name}}</el-checkbox>
                    </el-checkbox-group>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="基础业务" name="second">
                  <div class="single_choice">
                    <el-checkbox-group v-model="allData.minor_business_type.basics">
                      <el-checkbox v-for="(item,index) in businessBasicsType" :key="item.id" :label="item.id">{{item.dict_name}}</el-checkbox>
                    </el-checkbox-group>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="国际业务" name="third">
                  <div class="single_choice">
                    <el-checkbox-group v-model="allData.minor_business_type.international">
                      <el-checkbox v-for="(item,index) in businessInternationalType" :key="item.id" :label="item.id">{{item.dict_name}}</el-checkbox>
                    </el-checkbox-group>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>

        <!--      主要产品-->
        <div class="main_product"  v-if="!(allData.source_id == 79) || (allData.source_id == 79 && selectedProdcut.length > 0)">
          <div class="item">
            <span class="item_name" :style="{ paddingTop: opportunities ? '8px' : '14px' }">主要产品</span>
            <div>
              <button v-show="opportunities" v-if="!(allData.source_id == 79)" class="product_btn" @click="selectProduct">选择产品</button>
              <div class="selected_table" v-if="selectedProdcut.length>0">
                <div class="se_ul">
                  <div class="se_li" :class="{ se_li_hover: opportunities, 'se_li_uhover': allData.source_id === 79 }" v-for="(item,index) in selectedProdcut" :key="item.id">
                    <div class="st_left">
                      <img :src="item.product_img[0]" alt="">
                    </div>
                    <div class="st_right">
                      <div class="str1">{{item.name}}</div>
                      <div class="str2"><span style="color: #FD5451">￥{{item.price }}</span>/{{item.charge_method}}</div>
                    </div>
                    <div class="delete_box">
                      <div class="delete_product" @click="deleteProduct(index)"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main_solution">
          <div class="item">
            <span class="item_name">主要产品解决方案</span>
            <el-input
                type="textarea"
                :rows="2"
                placeholder="请填写主要产品解决方案"
                v-model="allData.product_solution">
            </el-input>
          </div>
        </div>
        <!--      附件-->
        <div class="attachments">
          <div class="item" >
            <span class="item_name">附件</span>
            <div style="display: flex" v-if="attachmentsShow">
              <el-upload
                  class="upload-demo"
                  action="/file/upload_single_file"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :before-remove="beforeRemove"
                  :on-success="uploadSuccess"
                  ref="upload"
                  multiple
                  :limit="3"
                  :on-exceed="handleExceed"
                  :file-list="fileList">
              </el-upload>
              <el-button size="small" type="primary" style="background: transparent;border-color: transparent;color: #3984F5" @click="handleReport">重新上传</el-button>
            </div>

            <div style="display: flex" v-else>
              <el-upload v-show="attachmentsShow1"
                  class="upload-demo"
                  action="/file/upload_single_file"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :before-remove="beforeRemove"
                  :on-success="uploadSuccess"
                  ref="upload"
                  multiple
                  :limit="3"
                  :on-exceed="handleExceed"
                  :file-list="fileList">
              </el-upload>
              <div v-show="!attachmentsShow1">
                <el-button size="small" type="primary" style="background: rgb(4,205,131);border-color: transparent;color: #fff;" @click="handleReport">上传附件</el-button> <span style="color: #A7B2B8;margin-left: 5px">支持扩展名: .doc .docx .xls .xlsx .pdf  .jpg .png .ppt .zip</span>
              </div>
              <el-button v-show="attachmentsShow1" size="small" type="primary" style="background: transparent;border-color: transparent;color: #3984F5" @click="handleReport">重新上传</el-button>
            </div>
          </div>
        </div>
<!--        跟进记录-->
        <div class="follow_record">
          <div class="item follow_item">
            <span class="item_name">跟进记录</span>
            <el-timeline v-if="recordList.length>0">
              <el-timeline-item v-for="(item, index) in recordList" :key="index">
                <div class="list"><span class="time">{{ item.update_time}}</span>
                  <div class="content">{{ item.content }}</div>
                </div>
              </el-timeline-item>
            </el-timeline>
            <div v-else style="margin-bottom: 20px">暂无跟进记录</div>
          </div>
          <a href="JavaScript:;" @click="add_record" :class="{ 'add_record': true, 'no_record': !recordList.length }">添加跟进记录</a>
        </div>
      </div>
    </div>
    <!--     确认 -->
    <div class="enter_btn">
      <button style="background: #3984F5;color: #fff" @click="editBusiness">确定</button>
      <button @click="backBtn">返回</button>
    </div>
<!--弹窗-->
    <el-dialog
        class="follow_closely"
        title="添加跟进记录"
        :visible.sync="dialogVisible1"
        width="30%"
        :before-close="handleClose">
      <el-input
          type="textarea"
          placeholder="请输入内容"
          v-model="follow_text"
          maxlength="100"
          show-word-limit
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible1 = false">取 消</el-button>
    <el-button type="primary" @click="follow_up_confirmation">确 定</el-button>
  </span>
    </el-dialog>

    <!--    弹窗-->
    <el-dialog class="select_product"
               title="选择产品"
               :visible.sync="dialogVisible"
               width="30%"
               :key="openNum"
               :before-close="handleClose1"
    >
      <div class="dialog_btn">
        <div class="item_btn">
          <span style="margin-right: 10px">产品分类</span>
          <!--          <el-cascader class="lv"-->
          <!--              v-model="value3"-->
          <!--              :options="options"-->
          <!--              :props="{ label: 'name' }"-->
          <!--              @change="handleChange"></el-cascader>-->
          <el-cascader v-model="typeText" :options="proClass" :props="{value: 'id', label: 'name'}" clearable></el-cascader>
        </div>
        <div class="item_btn">
          <span>产品名称</span>
          <el-input style="width: 240px;margin-left: 12px" v-model="nameText" placeholder="输入产品名称" clearable></el-input>
        </div>
        <button @click="search">查询</button>
      </div>
      <div class="dialog_table">
        <el-table
            ref="multipleTable"
            :data="tableData"
            :border="true"
            style="width: 100%"
            @selection-change="handleSelectionChange">
          <el-table-column
              type="selection"
              width="60"
              align="center">
          </el-table-column>
          <el-table-column label="产品信息" width="700px%">
            <template slot-scope="scope">
              <div class="product_info">
                <div class="r_logo">
                  <img :src="scope.row.product_img[0]" alt="">
                </div>
                <div class="r_content">
                  <h4>{{ scope.row.name }}</h4>
                  <div class="tag_box">
                    <div v-for="(item,index) in scope.row.tags" :key = 'index'>{{ item }}</div>
                  </div>
                  <p>{{ scope.row.feature}}</p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="address"
              label="价格">
            <template slot-scope="scope">
              <div class="price">
                ￥{{ scope.row.price }}元/年
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="operate">
          <div class="handle">
            <div class="all-select">
              <el-checkbox :indeterminate="isIndeterminate" v-model="allSelect" @change="toggleSelection()"></el-checkbox>
            </div>
            <div class="button" @click="toggleSelection()">全选</div>
            <!--            <div class="button">下载答卷</div>-->
          </div>
          <el-pagination
              :key="openNum"
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[4, 8, 12, 16]"
              :page-size='limit'
              layout="prev, pager, next, sizes"
              :total='total'
          >
          </el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="verifyData">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import address from "../questionnaire/components/address.js"
import {timeFormat} from "@/utils/index.js"
import {area_child_list, business_grid_info, business_info, business_list} from "../../request/businessOpportunityPool";
export default {
  name: "editorial",
  data(){
    return {
      selectedProdcut:[],
      allselectData:[],
      currentPage:1,
      page:1,
      attachments:{
        name:'',
        path:''
      },
      sjInfo:[
        {
          id:null,
          dict_name:''
        }
      ],
      typeText: [],//产品分类
      nameText: '',//产品名称
      proClass: [],//分类数据
      openNum:1,
      tableData:[],
      whetherToSwitchPages: false,
      total:null,
      limit:4,
      isIndeterminate: false, // 表格是否有选中但未全选
      allSelect: false, // 表格全选
      multipleSelection: [],
      loading: true,
      allbusiness:null,
      businessSource:[],//商机来源
      businessInnovateType:[],//创新业务
      businessBasicsType:[],//基础业务
      businessInternationalType:[],//国际业务
      owningGrid:'',//所属网格
      enterpriseName:'',//企业名称
      businessManager:'',//商企经理
      businessTitle:'', //商机名称
      businessList:[],
      opportunities:true,
      dialogVisible1:false,//弹窗
      dialogVisible:false,//弹窗
      attachmentsShow:false,
      attachmentsShow1:false,
      options1:[],
      recordList: [
        { content: '备注内容备注内容1备注内容备注内容1备注内容备注内容1备注内容备注内容1备注内容备注内容1备注内容备注内容1备注内容备注内容1', time: '2018-04-12 20:46' },
      ],
      regionalInformation:'',
      activeName: 'first',
      activeName1: 'first',
      fileList: [{
        name: 'food.jpeg',
        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'},],
      options:[],
      follow_text:'',
      textarea:'',
      checkList: ['选中且禁用','复选框 A'],
      input:'',
      value:'',
      allData:{
        id:null,
        area_id:null,
        grid_id:null,
        company_id:null,
        link_people: '',
        link_phone:'',
        business_manager_id:null,
        product_id:[],//产品id
        product_solution:'',
        name:'',
        source_id:null,
        predict_time:'',
        predict_contract_money:'',
        main_business_type:null,
        minor_business_type:{
          innovate:[],
          basics:[],
          international:[]
        },
        content:'',
        attachments:null,
      }
    }
  },
  methods:{
    selectProduct(){ //选择产品
      this.dialogVisible=true
      ++this.openNum;
      this.multipleSelection = this.selectedProdcut

      this.page = 1;
      this.getTableData()
    },
    toggleSelection1(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    getTableData() {
      let data = {
        page: this.page,
        limit: this.limit,
        name: this.nameText,
        cate_node_id: this.typeText.length>0?this.typeText[this.typeText.length-1]:'',
        status:1
      }
      this.$api.product_list(data).then(res=>{
        this.tableData = res.data.data
        this.total = res.data.count
        this.$nextTick(() => {
          console.log(this.multipleSelection, 'this.multipleSelection')
          const checkedList = this.tableData.filter(item => {
            return this.multipleSelection.map(selectedItem => selectedItem.id).includes(item.id)
          })
          this.toggleSelection1(checkedList)
        })
      })
    },
    handleClose1(){
      this.dialogVisible = false;
      ++this.openNum;
    },//弹窗关闭
    search() { //搜索
      this.page = 1;
      this.getTableData()
    },
    handleSelectionChange(val) {
      console.log(this.whetherToSwitchPages, '2222222222222')
      if (this.whetherToSwitchPages) {
        this.whetherToSwitchPages = false;
        return false;
      }
      this.allselectData[this.page - 1] = val;
      console.log(this.allselectData, this.page, 'allselectData')
      let list = [];
      // list.push(...this.selectedProdcut);
      this.allselectData.forEach(item => {
        item.forEach(i => {
          list.push(i)
        })
      })
      // console.log(list, 'list')

      this.multipleSelection = [...new Set(list)];

      console.log(this.multipleSelection)
      this.isIndeterminate = val.length > 0 && val.length < this.tableData.length;
      this.allSelect = val.length === this.tableData.length ? true : false;

    }, // 表格选中改变
    verifyData(){ //确认弹窗 确定的时候添加数据
      this.dialogVisible = false
      this.selectedProdcut = this.multipleSelection
    },
    handleCurrentChange(val){
      console.log(this.whetherToSwitchPages, '翻页')
      this.whetherToSwitchPages = true;
      this.page = val
      this.getTableData()
    },
    handleSizeChange(val){
      console.log(this.whetherToSwitchPages, '条数')
      this.whetherToSwitchPages = true;
      this.limit = val;
      this.getTableData()
    },
    deleteProduct(index){
      this.selectedProdcut.splice(index, 1);
    },
    getBusinesser(){
      this.$api.business_type_info().then(res=>{
        this.allbusiness = res.data.user
        let account = null;
        const selectedManager = this.allbusiness.find(manager => manager.id === this.allData.business_manager_id);
        // 如果找到了匹配的商企经理对象，则输出其 account 属性
        if (selectedManager) {
          account = selectedManager.account
        }
        this.businessManager = account //获取商企经理的值

      })
    },
    backBtn(){
      this.$router.push('/businessLeads')
    },
    handleChange(){},

// 点击导入
    handleReport() {
      this.attachmentsShow1 = false;
      this.$refs["upload"].$refs["upload-inner"].handleClick()
      this.fileList = []
    },
    handleClose(done){
            done();
    },
    follow_up_confirmation(){
      let data = {
        business_id:this.$route.query.id,
        content:this.follow_text
      }
      this.$api.business_add_follow(data).then(res=>{
        if (res.status == 200){
          this.$api.business_info({id:this.$route.query.id}).then(res=>{ //成功之后再次请求数据
            this.recordList = res.data.follows
          })
          this.$message({
            message: '添加成功！',
            type: 'success'
          });
          this.follow_text = '';
          this.dialogVisible1 = false;
        }
      })
    },
    add_record(){ //添加记录
      this.dialogVisible1 = true
    },
    handlePreview(){//上传附件

    },
    handleRemove(){

    },
    beforeRemove(){

    },
    handleExceed(){

    },
    editBusiness(){ //编辑确定按钮
      this.allData.product_id = this.selectedProdcut.map(item => item.id);
      this.$api.business_save(this.allData).then(res=>{
        if (res.status == 200){
          this.$message({
            message: '修改成功',
            type: 'success'
          });
          this.$router.push('/businessLeads')
        }
      })
    },
    uploadSuccess(response, file, fileList){ //上传成功之后的回调
      let fileData = {}
      fileData.path = response.data.file_url
      fileData.name = response.data.original_file_name
      this.allData.attachments = JSON.stringify(fileData);
      this.attachmentsShow1 = true;
    },
    handleDateChange(value){
      let time = timeFormat(value, 'yyyy-mm-dd')
      this.allData.predict_time = time
    },
    getActive(item){
      if (item.dict_code == 'innovate'){
        return 'first'
      }else if (item.dict_code == 'basics'){
        return 'second'
      }else if (item.dict_code == 'international'){
        return 'third'
      }
    },
    handleClick(){}
  },
  mounted() {
    // this.options = address;

    this.allData.id = this.$route.query.id

    this.$api.business_type_info().then(res=>{
      this.owningGrid = this.$route.query.gridName
      this.enterpriseName = this.$route.query.company
      this.businessSource = res.data.sourceInfo
      this.businessInnovateType = res.data.businessInnovateType.list
      this.businessBasicsType = res.data.businessBasicsType.list
      this.businessInternationalType = res.data.businessInternationalType.list
      this.businessList.push(res.data.businessInnovateType)
      this.businessList.push(res.data.businessBasicsType)
      this.businessList.push(res.data.businessInternationalType)
    })

    //详情
    this.$api.business_info({id:this.$route.query.id}).then(res=>{
      console.log(res,1245)
      this.recordList = res.data.follows
      this.allData.company_id = res.data.company_id
      this.allData.grid_id = res.data.grid_id
      this.allData.name = res.data.name
      this.businessTitle = res.data.name
      this.allData.area_id = res.data.area_id
      this.sjInfo[0].id = res.data.source_id
      this.sjInfo[0].dict_name = res.data.source_name
      this.regionalInformation = res.data.area_name
      if (res.data.attachments){
        let attachment = JSON.parse(res.data.attachments);
        this.fileList[0].name = attachment.name;
        this.fileList[0].url = attachment.path;
        console.log(attachment,8787)
        const isEmpty = (obj) => {
          if (obj === null || obj === undefined) {
            return true;
          }

          if (typeof obj === 'string' && obj.trim() === '') {
            return true;
          }

          if (Array.isArray(obj) && obj.length === 0) {
            return true;
          }

          if (typeof obj === 'object' && Object.keys(obj).length === 0) {
            return true;
          }

          return false;
        };

        if (isEmpty(attachment)) {
          this.attachmentsShow = false;
        } else {
          this.attachmentsShow = true;
        }
      }
      // this.fileList[0].name = res.data.attachments
      /*if (res.data.attachments){
        this.attachmentsShow = true
      }else {
        this.attachmentsShow = false;
      }*/
      this.allData.business_manager_id = res.data.business_manager_id
      this.allData.link_people = res.data.link_people
      this.allData.link_phone = res.data.link_phone
      this.allData.source_id = res.data.source_id
      let matchedSource = this.businessSource.find(source => source.id === res.data.source_id);
      let sourceName = matchedSource ? matchedSource.dict_name : "";
      if (sourceName == '诊断报告转商机'){
        this.opportunities = false
      }else {
        this.opportunities = true
      }
      this.allData.predict_time = res.data.predict_time
      this.allData.predict_contract_money = res.data.predict_contract_money
      this.allData.main_business_type = res.data.main_business_type
      let minor_business_type = JSON.parse(res.data.minor_business_type)
      this.selectedProdcut = res.data.product
      if (this.allData.source_id == 79){
        this.selectedProdcut = res.data.productInfo ? res.data.productInfo.map(item => item.product) : [];
      }else {
        this.selectedProdcut = res.data.product
      }
      if (minor_business_type){
        let keys = Object.keys(minor_business_type);
        keys.forEach(key => {
          // 使用 map 方法遍历数组，并将字符串转换为数字
          minor_business_type[key] = minor_business_type[key].map(str => parseInt(str));
        });
      }else {
        minor_business_type = {
          basics:[],
          innovate:[],
          international:[]
        }
        let keys = Object.keys(minor_business_type);
        keys.forEach(key => {
          // 使用 map 方法遍历数组，并将字符串转换为数字
          minor_business_type[key] = minor_business_type[key].map(str => parseInt(str));
        });
      }
      this.allData.minor_business_type.innovate = minor_business_type.innovate
      this.allData.minor_business_type.basics = minor_business_type.basics
      this.allData.minor_business_type.international = minor_business_type.international
      this.allData.content = res.data.content
      this.businessManager = res.data.business_manager_name
      this.allData.product_solution = res.data.product_solution
      // this.getBusinesser();
      this.loading = false //loading结束
    })

  }
}
</script>

<style scoped lang="scss">
.editorial_box{
  margin: 18px;
  padding: 30px;
  background: #FFFFFF;
  .enter_btn{
    margin-left: 110px;
    margin-top: 40px;
    button{
      width: 120px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #EEF1F3;
      border-radius: 4px;
      margin-right: 10px;
    }
  }
}
.enter_item{
  .title {
    position: relative;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 20px;
    &:before {
      position: absolute;
      left: -30px;
      top: 0;
      content: '';
      width: 4px;
      height: 20px;
      background-color: #3984F5;
    }
  }
  .input_box{
    padding: 0 44px;
    box-sizing: border-box;
    overflow: hidden;
    .item{
      margin-bottom: 13px;
      display: flex;
      align-items:center;
      float: left;
      margin-right: 63px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #21333F;
      i{
        color: #FD5451;
        margin-right: 2px;
      }
      .item_name{
        margin-right: 11px;
        display: inline-block;
        min-width: 56px;
        text-align: right;
      }
      ::v-deep .el-input__inner{
        height: 34px;
        width: 300px;
        line-height: 34px;
      }
      ::v-deep .el-input__icon{
        line-height: 34px;
      }
      ::v-deep .el-date-editor.el-input{
        width: 300px;
      }
      .lang_input{
        ::v-deep .el-input__inner{
          height: 34px;
          width: 1159px;
          line-height: 34px;
        }
      }
    }
  }
  .part2{
    margin-top: 27px;
    .item{
      margin-bottom: 18px;
    }
    .short_distance{
      margin-right: 45px;
    }
    .main_solution,.main_type,.attachments,.follow_record,.main_product{
      .item{
        display: flex;
        i{
          color: #FD5451;
          margin-right: 2px;
        }
        .item_name{
          width: 100px;
          margin-right: 10px;
          text-align: right;
        }
        ::v-deep .el-textarea{
          width: 1159px;
          .el-textarea__inner{
            height: 140px;
          }
        }
      }
    }
    .main_product{
      .item_name{
        //padding-top: 14px;
      }
      .product_btn{
        width: 80px;
        height: 34px;
        background: #3984F5;
        border-radius: 4px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 34px;
      }
      .selected_table{
        margin-top: 18px;
        border: 1px solid #CEDAE0;
        padding: 0 20px 20px 20px;
        width: 1159px;
        box-sizing: border-box;
        .se_ul{
          overflow: hidden;
        }
        .se_li{
          margin-top: 20px;
          position: relative;
          cursor: pointer;
          margin-right: 18px;
          float: left;
          display: flex;
          width: 360px;
          height: 108px;
          background: #FAFCFE;
          padding: 14px;
          box-sizing: border-box;
          &:nth-child(3n){
            margin-right: 0;
          }
          .st_left{
            flex-shrink: 0;
            margin-right: 14px;
            img{
              width: 80px;
              height: 80px;
              background: #CEDAE0;
              border-radius: 4px;
            }
          }
          .st_right{
            .str1{
              height: 46px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              font-size: 16px;
              color: #21333F;
              line-height: 23px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .str2{
              margin-top: 12px;
              color: #A7B2B8;
              font-size: 14px;
            }
          }
          .delete_box{
            width: 34px;
            height: 34px;
            background: #FFFFFF;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: none;
          }
          .delete_product{
            width: 18px;
            height: 20px;
            background: url("~@/assets/business/del_icon@2x.png") no-repeat center center;
            background-size: cover;
            cursor: pointer;
            margin: 0 auto;
            margin-top: 7px;
          }
        }
        .se_li_hover:hover::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #000000;
          opacity: 0.4;
        }

        .se_li_hover:hover .delete_box {
          display: block; /* hover 时显示按钮 */
        }
        .se_li_uhover:hover::before {
          background: transparent;
          opacity: 1; /* 或者根据需要修改其他样式 */
        }
        .se_li_uhover:hover .delete_box {
          display: none; /* hover 时显示按钮 */
        }
      }
    }
    .main_type{
      .item{
        display: flex;
        align-items: center;
      }
      .tags{
        ::v-deep .el-tabs{
          .el-tabs__active-bar{
            height: 0;
          }
          .el-tabs__nav-wrap::after{
            height: 0;
          }
          .el-tabs__item{
            margin-right: 10px;
            background: #EEF1F3;
            border-radius: 4px;
            padding: 0 20px;
          }
          .el-tabs__item.is-active{
            background: #3984F5;
            color: #fff;
          }
        }
      }
      .single_choice{
        //margin-top: 18px;
      }
    }
    .tab_main{
      .item_name{
        margin-top: 10px;
      }
      .item{
        display: flex;
        align-items: flex-start;
      }
    }
    .attachments{
      .item{
        display: flex;
        align-items: center;
      }
      .upload-demo{
        display: flex;
        align-items: center;
        .el-button{
          height: 40px;
          width: 120px;
        }
        ::v-deep .el-upload-list{
          background: #F6F7F9;
          padding-bottom: 8px;
          width: 620px;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
    .follow_record{
      .follow_item{
        margin-bottom: 0;
      }
      .el-timeline{
        font-size: 12px;
      }
      .add_record{
        color: #3984F5;
        margin-left: 140px;
        margin-top: -20px;
      }
      .add_record.no_record{
        margin-left: 112px;
      }
      .list {display: flex;font-size: 12px;color: #6C7880;}
      .time {margin-right: 58px;}
      .content {flex: 1;}
    }
  }
}
::v-deep .el-timeline {flex: 1;margin-top: 2px;
  .el-timeline-item {padding-bottom: 30px;}
  .el-timeline-item__node {width: 13px;height: 13px;border: 2px solid #3984F5;background: #EEF3FB;box-sizing: border-box;}
  .el-timeline-item__tail {left: 5px;border-left: 1px solid #CEDAE0;}
}
::v-deep .follow_closely{
  .el-dialog{
    margin-top: 30vh !important;
    width: 500px !important;
    .el-textarea__inner{
      height: 136px;
    }
    .el-dialog__footer{
      text-align: center;
    }
  }
}
.select_product{
  ::v-deep .el-dialog{
    width: 50% !important;
  }
  .dialog_btn{
    display: flex;
    align-items: center;
    .lv{
      margin-left: 12px;
    }
    .item_btn{
      display: flex;
      align-items: center;
      margin-right: 30px;
    }
    button{
      width: 64px;
      height: 34px;
      background: #3984F5;
      border-radius: 4px;
      text-align: center;
      font-weight: 400;
      font-size: 12px;
      color: #FFFFFF;
      line-height: 24px;
    }
  }
  .operate {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .handle {
      display: flex;
      .all-select {
        margin-right: 16px;
        padding-left: 24px;
        line-height: 32px;
      }
      .button {
        margin-right: 10px;
        padding: 0 12px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #6C7880;
        border: 1px solid #CEDAE0;
        cursor: pointer;
        &:hover {
          color: #3984F5;
          border-color: #3984F5;
        }
      }
    }
  }
  ::v-deep .el-table {
    margin-top: 20px;
    font-size: 12px;
    color: #242D33;
    border-color: #DCE5EC;
    &::before,
    &::after {
      background-color: #DCE5EC;
    }
    .el-table__cell {
      padding: 8px 0;
      border-color: #DCE5EC;
    }
    .cell {
      line-height: 24px;
    }
    thead {
      color: #242D33;
    }
    th.el-table__cell.is-leaf {
      border-color: #DCE5EC;
      background: #F6F7F9;
    }
    th {
      font-weight: 400;
    }
    .el-table-column--selection .cell {
      padding-right: 10px;
    }
    .el-table__body tr:hover > td.el-table__cell {
      background-color: #F6FBFE;
    }
    td.el-table__cell {
      padding: 13px 0;
    }
  }
  .product_info{
    display: flex;
    align-items: center;
    .r_logo{
      //margin-top: 20px;
      width: 84px;
      height: 84px;
      margin-right: 20px;
      flex: 0 0 auto;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .r_content{
      //margin-top: 10px;
      flex-grow: 1;
      h4{
        font-weight: 400;
        font-size: 16px;
        color: #242D33;
        line-height: 36px;
      }
      .tag_box{
        overflow: hidden;
        div{
          float: left;
          padding: 7px 11px 8px 10px;
          color: #3984F5;
          background: #EFF5FF;
          margin-right: 8px;
          font-size: 12px;
        }
      }
      p{
        margin-top: 7px;
        font-weight: 400;
        font-size: 12px;
        color: #6C7880;
        line-height: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .price{
    color: red;
  }
}
</style>